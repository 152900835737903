/*
 * @Author: liuzeming1 liuzeming@storswift.com
 * @Date: 2022-10-20 16:31:15
 * @LastEditors: liuzeming1
 * @LastEditTime: 2022-10-20 17:14:24
 * @Description: 文件作用描述
 */
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Dispatch } from "react"


interface SwitchProps {
  open?: boolean
  openDrawer: () => void
}
export const DrawerSwitch = (props: SwitchProps) => {
  const {open, openDrawer} = props
  return (
    <>
    <div className={'graph_stow-switch'}>
            {
              open ?
                <DoubleRightOutlined onClick={openDrawer}/>
                :
                <DoubleLeftOutlined onClick={openDrawer} />
            }
          </div>
    </>
  )
}