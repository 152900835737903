/*
 * @Author: liuzeming1 liuzeming@storswift.com
 * @Date: 2022-11-08 09:29:33
 * @LastEditors: liuzeming1
 * @LastEditTime: 2022-11-11 14:38:30
 * @Description: 文件作用描述
 */
/**
 * @description: 用于转换Fil单位，将nanoFIl转换为Fil
 * @param {any} initial	nanoFil的数量
 * @param {number} power	转换为FIL单位时使用默认值18，无需穿参
 * @return {*}	转换后的金额字符串
 */
export function transFil(initial: any, power: number = 18) {
    let times = 10 ** power;
    return (Number(initial) / times);
}


export function isHasFixed(value: number) {
    let arr = value.toString().split(".");
    return arr.length > 1
}
